<template>
  <v-carousel
    cycle
    height="auto"
    class="pa-0"
    delimiter-icon="mdi-minus"
  >
    <v-carousel-item
      v-for="(item,i) in items"
      :key="i"
      :src="item.src"
      reverse-transition="fade-transition"
      fade
      eager
    >
      <v-img :src="item.src" eager/>
    </v-carousel-item>
  </v-carousel>
</template>
<script>
export default {
  data () {
    return {
      items: [
        {
          src: require('@/assets/images/01.jpg')
        },
        {
          src: require('@/assets/images/02.jpg')
        },
        {
          src: require('@/assets/images/03.jpg')
        },
        {
          src: require('@/assets/images/04.jpg')
        },
        {
          src: require('@/assets/images/05.jpg')
        },
        {
          src: require('@/assets/images/06.jpg')
        },
        {
          src: require('@/assets/images/07.jpg')
        },
        {
          src: require('@/assets/images/08.jpg')
        },
        {
          src: require('@/assets/images/09.jpg')
        },
        {
          src: require('@/assets/images/10.jpg')
        },
        {
          src: require('@/assets/images/11.jpg')
        }
      ]
    }
  }
}
</script>