<template>
  <div>
    <TopParallax
      title="HERE'S WHAT'S BEEN HAPPENING RECENTLY"
      :img-src="imgSrc"
    />
    <NewsContent />
  </div>
</template>

<script>
import TopParallax from '@/components/TopParallax.vue'
import AboutBar from '@/components/hope/AboutBar.vue'
import NewsContent from '@/components/hope/NewsContent.vue'

export default {
  components: {
    TopParallax,
    NewsContent
  },
  data() {
    return {
      imgSrc: require('@/assets/images/news5.jpg')
    }
  },
  head: {
    title: function () {
      return {
        inner: 'News'
      }
    }
  }
}
</script>
