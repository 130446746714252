<template>
  <div>
    <v-card rounded="0">
      <v-container class="py-4">
        <h4 class="h4 text-center">HOPE AND ANGELS VOLUNTEERS DELIVERED FOOD IN MAY, 2020</h4>
        <v-divider />
        <v-row>
          <v-col cols="12" lg="6">
            <h5 class="h5 my-4">Two times in the month of May during the lockdown, God moved on the hearts of compassionate good samaritans in Luanda. They brought boxes of food, including 600 dinner rolls, to our Hope and Angels school. That food was then distributed to families by Hope and Angels volunteers. All the families of our special children received these blessings.</h5>
          </v-col>
          <v-col cols="12" lg="6">
            <Food2Carousel />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card rounded="0" color="#f3f2ed">
      <v-container>
        <h4 class="h4 text-center">ESPERANCA VISITED THE CHILDREN IN MUPA AND LUANDA IN 2019</h4>
        <v-divider />
        <v-row>
          <v-col cols="12" lg="4">
            <v-img class="mt-2" src="@/assets/images/news1.jpg" />
          </v-col>
          <v-col cols="12" lg="8">
            <h4 class="h4 mt-2"><b>Highlights of Esperanca’s Trip to Angola</b></h4>
            <h5 class="h5 mt-2">1. Esperanca and a team of volunteers had the joy of interacting with the children at the Missao de Mupa as well as the children in the Community Program in Luanda from October 16 to November 22, 2019.</h5>
            <h5 class="h5 mt-2">2. They met with teachers in Mupa and Luanda to discuss goals, initiatives, needs and challenges facing the children who are cared for by Hope and Angels Foundation.</h5>
            <h5 class="h5 mt-2">3. The team engaged in strategic discussions with Pastor Jacob from the Republic of Namibia. Dialogue focused on:</h5>
            <v-container class="py-0">
              <h5 class="h5 mb-2"><v-icon>mdi-circle-medium</v-icon>digging a water well and installing solar panels at the Orphanage/Boarding School in Mupa</h5>
              <h5 class="h5 my-2"><v-icon>mdi-circle-medium</v-icon>agricultural initiatives  --  establishing viable forms of sustainable income (i.e. garden vegetables, bakery, poultry/eggs)</h5>
              <h5 class="h5 my-2"><v-icon>mdi-circle-medium</v-icon>education  --  offering high school scholarships to attend a boarding school in Namibia to children from the Missao de Mupa once they complete Grade 9</h5>
            </v-container>
            <h5 class="h5 mt-2">4. Donations and supplies were delivered to severely disadvantaged children in the Province of Huila (located 16 km from Lubango, Angola)</h5>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card rounded="0">
      <v-container>
        <h4 class="h4 text-center">Esperanca and a team of volunteers plan to visit the children in Mupa, Luanda and Ondjiva<br>when international travel safely resumes.<br>It is hoped this visit will take place sometime in 2022.<br>But due to the covid pandemic, travel plans are on hold at this time.</h4>
        <v-divider class="mb-4" />
        <v-row>
          <v-col cols="6" lg="3">
            <v-img src="@/assets/images/news2.jpg" />
            <h5 class="h5 text-center">Esperanca with Pastor Jacob from Namibia</h5>
          </v-col>
          <v-col cols="6" lg="3">
            <v-img src="@/assets/images/news3.jpg" />
            <h5 class="h5 text-center">When Hope visited the children in the Province of Huila</h5>
          </v-col>
          <v-col cols="6" lg="3">
            <v-img src="@/assets/images/news4.jpg" />
          </v-col>
          <v-col cols="6" lg="3">
            <v-img src="@/assets/images/news5.jpg" />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card rounded="0" color="#f3f2ed">
      <h4 class="h4 text-center">HOPE AND ANGELS ANSWERS THE CRIES OF A DESPERATE MOTHER</h4>
      <v-divider class="mb-4" />
      <v-container>
        <v-row>
          <v-col cols="12" lg="4">
            <v-img src="@/assets/images/news6.jpg" />
          </v-col>
          <v-col cols="12" lg="8">
            <h5 class="h5">A mother cried out to Hope and Angels Foundation for a wheelchair for her 12 year old daughter, Claudia. Stricken with meningitis at an early age, Claudia is now unable to walk or talk. The mother was no longer able to carry her daughter on her back to physiotherapy sessions. In desperation, she sent out a plea that fell on the compassionate ears of Hope and Angels volunteers. Generous donours answered the call and a wheelchair was delivered to the child’s home in Angola. This mother now beams with delight every day when she is able to put Claudia into a wheelchair and push her to her physiotherapy appointments. It’s the mother’s hope that one day her daughter will be able to walk again.</h5>
          </v-col>
        </v-row>
        <h4 class="h4 text-center mt-4">HOPE AND ANGELS ANSWERS THE CRIES OF A DESPERATE MOTHER</h4>
        <v-divider class="mb-4" />
        <v-row>
          <v-col cols="12" lg="6">
            <v-img src="@/assets/images/visits.jpg" />
          </v-col>
          <v-col cols="12" lg="6">
            <h5 class="h5">Everyone who has shown loving support towards Claudia will be delighted to know she has remained healthy and well throughout the covid lockdown.</h5>
            <h5 class="h5 my-4">During this period of isolation, the staff at Hope and Angels Foundation has been monitoring her situation. Here is a photo taken recently of their visit with Claudia at her home.</h5>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card rounded="0">
      <v-container>
        <h4 class="h4 text-center mt-4">HOPE AND ANGELS PROVIDES FUNDS FOR MEDICAL ASSISTANCE FOR PAULO</h4>
        <v-divider class="mb-4" />
        <h5 class="h5">Thank you Hope and Angels donours!! Due to your generosity, Paulo in Luanda has a chance for a brighter tomorrow. It was a Hope and Angels volunteer who first came in contact with Paulo. He was 15 years old and on the streets of Luanda. He was begging for food and trying to polish shoes in order to make a small amount of money. Paulo’s life was full of rejection and abuse due to his physical appearance. He had been enrolled in school. But he was severely bullied by his peers and this forced him to discontinue his schooling. In addition, his father lost his job and was no longer able to pay for Paulo’s school fees.</h5>
        <h5 class="h5 my-4">A Hope and Angels volunteer recognized that Paulo needed medical assistance. Donours reached out to Paulo and covered the cost of his medical bills. Now he has a hopeful future -- he’s returning to school in order to fulfill his dream of achieving his academic goals.</h5>
        <v-row>
          <v-col cols="12" lg="4">
            <h4 class="h4">Paulo before surgery</h4>
            <v-img src="@/assets/images/news7.jpg" />
          </v-col>
          <v-col cols="12" lg="4">
            <h4 class="h4">Paulo after surgery</h4>
            <v-img src="@/assets/images/news8.jpg" />
          </v-col>
          <v-col cols="12" lg="4">
            <v-img src="@/assets/images/news9.jpg" />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>
<script>
import CovidCarousel from '@/components/hope/CovidCarousel.vue'
import FoodCarousel from '@/components/hope/FoodCarousel.vue'
import Food2Carousel from '@/components/hope/Food2Carousel.vue'

export default {
  components: {
    Food2Carousel
  }
}
</script>